.dropzone {
  border: 2px dashed var(--secondary);
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone:hover {
  border-color: var(--primary);
  background-color: rgba(var(--primary-rgb), 0.05);
}

.browseButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--gray-1);
  color: var(--secondary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.browseButton:hover {
  background-color: var(--primary);
  color: var(--gray-1);
}
