.settingsPanel {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  height: 50%;
}

.setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
}

.select {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  margin: 0;
}

.downloadButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--secondary);
  width: 100%;
  justify-content: center;
  color: var(--secondary);
  background-color: white;
  transition: border 1s, color 0.3s;
}

.uploadButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  justify-content: center;
}

.downloadButton:hover{
  background-color: var(--gray-1);
}

.uploadButton:hover {
  background-color: var(--primary);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}