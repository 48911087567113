.main {
  min-height: 70vh;
  padding: 2rem;
  background-color: var(--background);
  color: var(--foreground);
  transition: background-color 0.3s, color 0.3s;
}

.main {
  --background: #ffffff;
  --foreground: #333333;
  --primary: #FF6200;
  --secondary: #243750;
  --accent: #c0392b;
  --gray-1: #F5F5F5;
}

.title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.container {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
  height: 70vh;
}

.uploadSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }
}
