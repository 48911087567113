.logSection {
  margin-top: 2rem;
}

.logContainer {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.logEntry {
  margin: 0;
  padding: 0.25rem 0;
  font-family: monospace;
}
