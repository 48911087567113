.filePreview {
  margin-top: 1rem;
}

.fileList {
  list-style-type: none;
  padding: 0;
  max-height: 14rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fileItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 10px 20px;
  background: #F5F5F5;
  border-radius: 10px;
}
